<template>
  <div>
    <div class="lg:flex lg:space-x-10">
      <div class="w-full lg:px-20 space-y-7">
        <div class="card mt-4 p-3">
          <div class="relative -mt-3">
            <div class="px-1 py-3">
              <ul class="uk-child-width-1-4@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-small uk-grid">
                <li v-for="fcat in fcats.data" :key="'fcat' + fcat.id" class="mb-3"
                >
                  <router-link :title="fcat.title" :to="{name: 'FcatDetail', params: {slug: fcat.slug}}">
                    <div class="rounded-md overflow-hidden relative w-full h-20 bg-blue-500">
                      <div class="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10">
                      </div>
                      <div class="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg">{{ fcat.title }}</div>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import {changePageTitle} from "../../core/services/utils.service";

export default {
  name: "FcatList",
  components: {
  },
  data() {
    return {
      fcats: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      }
    }
  },
  methods: {
    loadFcats() {
      let query = `query {
        fcats(first: 1000, orderBy: [{ column: "title", order: ASC }]) {
          data {
            id
            title
            slug
            views
          }
        }
      }
      `;

      ApiService.graphql(query, {page: this.fcats.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.fcats) {
              this.fcats = data.data.fcats;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    }
  },
  mounted() {
    this.loadFcats();
    changePageTitle("Thể loại dân ca");
  }
}
</script>
